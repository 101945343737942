import { useContext, useState } from 'react'
import { AppContext } from '../../utils/context/AppContext'
import useTranslate from '../../utils/hooks/useTranslate'
import { getDefaultNewTitle, getDefaultTemplates } from '../../wizards/sendEmailWizard/sendEmailUtils'
import EmailTemplate from '../../wizards/sendEmailWizard/subPages/EmailTemplate'
import useTemplateUpdates from '../../wizards/sendEmailWizard/useTemplateUpdates'
import ScrollContainer from '../scrollContainer/ScrollContainer'

const EmailTemplateSettingsComponent = () => {
  const context = useContext(AppContext)
  const customerName = context.completeDataSet.customer.customerName
  const t = useTranslate()
  const defaultTemplates = getDefaultTemplates(customerName, t)
  const [data, setData] = useState({
    availableTemplates: [...defaultTemplates, ...context.completeDataSet.ptInvitationTemplates],
    emailType: 'invitation',
    emailSubject: defaultTemplates[0].templateData.subject,
    topText: defaultTemplates[0].templateData.topText,
    bottomText: defaultTemplates[0].templateData.bottomText,
    templateTitle: defaultTemplates[0].title,
    templateKey: defaultTemplates[0].publicKey,
    salutationType: defaultTemplates[0].templateData.salutationType,
    saveMethod: 'add',
    newTemplateTitle: getDefaultNewTitle(defaultTemplates[0].title, 'add', t),
    isDirty: false
  })

  useTemplateUpdates(data, setData, defaultTemplates)

  return (
    <div>
      <ScrollContainer type="settings">
        <div style={{ marginBottom: 'var(--space-8)' }}>
          <EmailTemplate {...{ data, setData }} />
        </div>
      </ScrollContainer>
    </div>
  )
}

export default EmailTemplateSettingsComponent
