import { Screen } from '../../../entities/Screen'
import {
  DEFAULT_NORM_NR,
  DEFAULT_SCREEN_NR_START,
  NEW_NORM_NR,
  NEW_SCREEN_NR,
  NORM_KEY,
  SCREEN_KEY,
  SCREEN_TYPE_END,
  SCREEN_TYPE_START,
  STATUS_BAR_TYPES
} from '../../../utils/constants/constants'
import { setStatusBar } from '../../../utils/helper/Helper'
import { createDate } from '../../../utils/helper/dateTimeHelper'
import useTranslate from '../../../utils/hooks/useTranslate'
import DateDisplay from '../../dateDisplay/DateDisplay'
import NormsHelp from '../../helpModal/NormsHelp'
import ScreensHelp from '../../helpModal/ScreensHelp'
import { getAllNorms } from '../normEditor/normUtils'
import { getScreenTemplates } from '../screenEditor/screenUtils'

export const showLoadingIndicator = (context) => {
  setStatusBar({
    controller: context.statusBarController,
    type: STATUS_BAR_TYPES.loading,
    text: 'processingInProgress',
    setVisible: true
  })
}

export const getEditorOptionsWithDetails = (allElements, t, keyName) => {
  return allElements.map((element) => ({
    label: (
      <>
        <div style={{ display: 'grid' }}>
          <span>
            {element.name}{' '}
            {element.isDirty ? <span style={{ color: 'var(--text-color-secondary)' }}> ({t('changed')})</span> : ''}
          </span>
          <span style={{ color: 'var(--text-color-secondary)', fontSize: 'var(--fs-1)', transform: 'translateY(1px)' }}>
            <NormDetails dateCreated={element.created} createdBy={element.createdBy} />
          </span>
        </div>
      </>
    ),
    value: element[keyName]
  }))
}

const NormDetails = ({ dateCreated, createdBy }) => {
  const t = useTranslate()
  const date = dateCreated instanceof Date ? dateCreated : createDate(dateCreated)

  return (
    <span>
      {t('created')}
      {dateCreated && (
        <>
          {' '}
          {t('onDate')} <DateDisplay date={date} />
        </>
      )}
      <>
        {' '}
        {t('by')} {createdBy}
      </>
    </span>
  )
}

export const getEditorConfig = (type, context, t) => {
  switch (type) {
    case 'norms':
      return {
        initialElements: getAllNorms(context.completeDataSet.customNorms, t),
        initialKeyValue: DEFAULT_NORM_NR,
        helpLabel: 'gradingRules',
        elementName: 'gradingRule',
        addButtonContent: 'rule',
        helpContent: <NormsHelp />,
        keyName: NORM_KEY,
        newKeyValue: NEW_NORM_NR,
        overwriteHeadline: 'overwriteNorm',
        confirmDeleteText: 'confirmNormDelete',
        deleteModalHeadline: 'deleteNorm',
        endpointSave: 'save_custom_norm',
        endpointDelete: 'delete_custom_norm',
        showLanguageSelect: false,
        saveSuccessMessage: 'ruleSavedSuccessfully',
        hasBoundaryErrorsFn(currentElement) {
          return currentElement?.normTable.some((row) => row.upperTooLow)
        },
        isNewElement(keyValue) {
          return keyValue === NEW_NORM_NR
        },
        hasEmptyFieldsFn(currentElement) {
          return currentElement.normTable.some((row) => row.grade === '' || row.verbalisation === '')
        }
      }
    case 'screens':
      return {
        initialElements: getScreenTemplates(context.completeDataSet.customScreens, t),
        initialKeyValue: DEFAULT_SCREEN_NR_START,
        helpLabel: 'screens',
        elementName: 'screen',
        addButtonContent: 'screen',
        helpContent: <ScreensHelp />,
        keyName: SCREEN_KEY,
        newKeyValue: NEW_SCREEN_NR,
        overwriteHeadline: 'overwriteScreen',
        confirmDeleteText: 'confirmScreenDelete',
        deleteModalHeadline: 'deleteScreen',
        endpointSave: 'save_custom_screen',
        endpointDelete: 'delete_custom_screen',
        showLanguageSelect: true,
        saveSuccessMessage: 'screenSavedSuccessfully',
        hasBoundaryErrorsFn() {
          return false
        },
        isNewElement(keyValue) {
          return keyValue === NEW_SCREEN_NR
        },
        hasEmptyFieldsFn(currentElement) {
          return currentElement.content.headline === '' || currentElement.content.body === ''
        }
      }
    default:
      throw new Error('Unknown element type')
  }
}

export const getEmptyElement = (keyName, t, contact, options) => {
  const EMPTY_NORM = {
    name: t('newRule'),
    normNr: NEW_NORM_NR,
    createdBy: contact.contactEmail,
    created: new Date(),
    normTable: [
      {
        lower_limit: 0,
        upper_limit: 50,
        grade: '',
        verbalisation: ''
      },
      {
        lower_limit: 50,
        upper_limit: 100,
        grade: '',
        verbalisation: ''
      }
    ]
  }

  const EMPTY_START_SCREEN = new Screen({
    name: t('newStartScreen'),
    screenNr: NEW_SCREEN_NR,
    type: SCREEN_TYPE_START,
    createdBy: contact.contactEmail,
    created: new Date(),
    html: {
      de: `<h1>Herzlich willkommen</h1><p>Inhalt (Anrede: Sie)</p>`,
      en: `<h1>Welcome</h1><p>Content</p>`,
      ded: `<h1>Herzlich willkommen</h1><p>Inhalt (Anrede: Du)</p>`
    }
  })

  const EMPTY_END_SCREEN = new Screen({
    name: t('newEndScreen'),
    screenNr: NEW_SCREEN_NR,
    type: SCREEN_TYPE_END,
    createdBy: contact.contactEmail,
    created: new Date(),
    html: {
      de: `<h1>Test abgeschlossen</h1><p>Inhalt (Anrede: Sie)</p>`,
      en: `<h1>Test completed</h1><p>Content</p>`,
      ded: `<h1>Test abgeschlossen</h1><p>Inhalt (Anrede: Du)</p>`
    }
  })

  switch (keyName) {
    case NORM_KEY:
      return EMPTY_NORM
    case SCREEN_KEY:
      return options === 'startScreen' ? EMPTY_START_SCREEN : EMPTY_END_SCREEN
    default:
      throw new Error('Unknown element type')
  }
}

export const getDefaultKey = (keyName) => {
  switch (keyName) {
    case NORM_KEY:
      return DEFAULT_NORM_NR
    case SCREEN_KEY:
      return DEFAULT_SCREEN_NR_START
    default:
      throw new Error('Unknown element type')
  }
}
