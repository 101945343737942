import Select from 'react-select'
import TimePicker from '../../components/timePicker/TimePicker'
import { getOptionByValue, hasSpecialChars, isString, updateEntities, updateResults } from '../../utils/helper/Helper'
import {
  DEFAULT_DIM_LOGIC_NR,
  DEFAULT_NORM_NR,
  DEFAULT_SCREEN_NR_END,
  DEFAULT_SCREEN_NR_START,
  ENTITIES,
  FORM_ERRORS,
  MAX_CHARS,
  STATUS_BAR_TYPES,
  THE_FUTURE,
  THE_PAST
} from '../../utils/constants/constants'
import { fetchData, setStatusBar } from '../../utils/helper/Helper'
import { ButtonPrimary, ButtonSecondary, ErrorMessage, selectStyles } from '../../utils/elements/miscElements'
import Calendar from '../calendar/Calendar'
import { createDate, formatDate } from '../../utils/helper/dateTimeHelper'
import { CalendarWrapper } from './customInputElements'
import {
  applyBulkEdit,
  getPtsWhereValidFromIsValid,
  getPtsWhereValidUntilIsValid,
  getValidPtsForBulkChanges
} from '../bulkEdit/BulkUtils'
import { useState } from 'react'
import useTranslate from '../../utils/hooks/useTranslate'
import TestLanguagesSelect from '../testLanguagesSelect/TestLanguagesSelect'

export const getSendValue = (props, value) => {
  const { type, isGlobalStatus, dataKey, isCustomField, isNorm, isDimensionLogic, isScreen } = props
  const val = isString(value) ? value.trim() : value
  if (type === 'calendar') return formatDate(val)?.androFormat || ''
  if (val === null) return ''
  if (isGlobalStatus) return [{ statusKey: dataKey, statusValue: val }]
  if (isCustomField) return [{ customFieldKey: dataKey, customFieldValue: val }]
  if (isNorm && value === DEFAULT_NORM_NR) return ''
  if (isDimensionLogic && value === DEFAULT_DIM_LOGIC_NR) return ''
  if (isScreen && [DEFAULT_SCREEN_NR_START, DEFAULT_SCREEN_NR_END].includes(value)) return ''

  return val
}

export const getValueToDisplay = (props) => {
  switch (props.type) {
    case 'select':
    case 'time-picker':
    case 'calendar':
    case 'languageSelect':
      return props.value
    default:
      return props.content
  }
}

export const saveCustomInput = async (props, value, context) => {
  const endPoint = props.apiData.endPointEdit
  const payload = getPayload(props, value)
  const responseData = await fetchData(payload, endPoint, context, 'dataChangeFailed')

  const resultNrs = responseData?.response?.resultsToUpdate?.resultNrs || []
  const processResultNrs = responseData?.response?.resultsToUpdate?.processResultNrs || []

  try {
    updateEntities(responseData.response.data, context)
    await updateResults(resultNrs, processResultNrs, context)
    setStatusBar({
      controller: context.statusBarController,
      type: STATUS_BAR_TYPES.success,
      text: ['fieldChangedSuccessfully', props.label]
    })
  } catch (e) {
    console.error(e)
  }
}

const getPayload = (props, value) => {
  const idKey = props.apiData.idKey
  const idKeySecondary = props.apiData.idKeySecondary
  const sendValue = getSendValue(props, value)
  const dataKey = getDataKey(props)

  const payload = {
    [idKey]: [props.idValue],
    [dataKey]: sendValue
  }

  if (idKeySecondary) {
    payload[idKeySecondary] = [props.idValueSecondary]
  }

  return payload
}

const getDataKey = (props) => {
  const { isGlobalStatus, isCustomField } = props
  if (isGlobalStatus) return 'globalStatus'
  if (isCustomField) return 'customFields'
  return props.dataKey
}

export const InputElement = ({ props, value, setValue, isValid, setIsValid, closeAndSave }) => {
  const t = useTranslate()
  const [errorMsg, setErrorMsg] = useState('')

  const handleInputChange = (e) => {
    const value = e.target.value
    validateInput(value, props, setIsValid, setErrorMsg, t)
    setValue(value)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      closeAndSave()
    }
  }

  switch (props.type) {
    case 'input':
    case 'email':
      return (
        <div style={{ display: 'grid' }}>
          <input
            type="text"
            value={value || ''}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            autoFocus
            className={!isValid && errorMsg ? 'has-error' : ''}
          />
          <ErrorMessage visible={errorMsg}>{errorMsg}</ErrorMessage>
        </div>
      )
    case 'select':
      return (
        <div style={{ gridRow: 2 }}>
          <Select
            options={t(props.options)}
            styles={selectStyles}
            isSearchable={false}
            value={getOptionByValue(t(props.options), value)}
            onChange={(selectedOption) => {
              if (selectedOption?.value === null || selectedOption?.value === undefined) {
                setValue('')
              } else {
                setValue(selectedOption?.value)
              }
            }}
            autoFocus
            isOptionDisabled={(option) => option.isDisabled}
            isClearable={value && props?.isClearable}
            placeholder=""
          />
        </div>
      )
    case 'textarea':
      return (
        <div style={{ display: 'grid' }}>
          <textarea type="text" value={value || ''} onChange={handleInputChange} autoFocus />
          <ErrorMessage visible={errorMsg}>{errorMsg}</ErrorMessage>
        </div>
      )

    case 'calendar':
      return (
        <CalendarWrapper {...props}>
          <Calendar value={value} setData={setValue} autoFocus fieldName={props.dataKey} hasError={!isValid} />
        </CalendarWrapper>
      )
    case 'time-picker':
      return (
        <TimePicker
          seconds={value}
          setData={setValue}
          field={props.dataKey}
          autoFocus
          minDuration={props.minDuration}
          setIsValid={setIsValid}
        />
      )
    case 'languageSelect':
      return (
        <TestLanguagesSelect
          languageIds={value}
          languageOptions={props.options}
          setterFunction={setValue}
          setIsValid={setIsValid}
        />
      )
    default:
      return <></>
  }
}

const validateInput = (value, props, setIsValid, setErrorMsg, t) => {
  const maxChars = props.maxChars || MAX_CHARS.default
  const isAboveMaxCharLimit = value.length > maxChars
  const errorMessages = {
    tooManyChars: t('maxCharsPolite', maxChars),
    noSpecialChars: t(FORM_ERRORS.forbiddenCharacter),
    isEmpty: t(FORM_ERRORS.fieldEmpty)
  }

  if (isAboveMaxCharLimit) {
    setErrorMsg(errorMessages.tooManyChars)
    setIsValid(false)
    return
  }

  if (hasSpecialChars(value)) {
    setErrorMsg(errorMessages.noSpecialChars)
    setIsValid(false)
    return
  }

  if (props.cannotBeEmpty && value.trim().length === 0) {
    setErrorMsg(errorMessages.isEmpty)
    setIsValid(false)
    return
  }

  setIsValid(true)
  setErrorMsg('')
}

export const getReferenceDate = (props, assessments, pts) => {
  const assessment = assessments.find((ass) => ass.assessmentUuid === props.idValue)
  const pt = pts.find((pt) => pt.ptNumber === props.idValue)
  const isAssessment = props.apiData.idKey === 'assessmentUuid'
  const validFrom = isAssessment ? getValidFrom(assessment, 'validFrom') : getValidFrom(pt, 'ptValidFrom')
  const validUntil = isAssessment ? getValidUntil(assessment, 'validUntil') : getValidUntil(pt, 'ptValidUntil')

  return {
    key: props.dataKey === 'validUntil' ? 'validFrom' : 'validUntil',
    date: props.dataKey === 'validUntil' ? validFrom : validUntil
  }
}

const getValidFrom = (entity, dataKey) => (entity[dataKey] ? createDate(entity[dataKey]) : createDate(THE_PAST))
const getValidUntil = (entity, dataKey) => (entity[dataKey] ? createDate(entity[dataKey]) : createDate(THE_FUTURE))

export const validateDates = (date, props, assessments, pts) => {
  const referenceDate = getReferenceDate(props, assessments, pts)
  if (referenceDate.key === 'validFrom') {
    return date > referenceDate.date
  } else {
    return date < referenceDate.date
  }
}

export const getCopyToPtModalProps = (context, ptsForBulk, props, value, setEditMode, t) => ({
  context: context,
  t: t,
  ptsForBulk: ptsForBulk,
  buttonPrimary: (
    <ButtonPrimary
      modalButton
      content="applyChangesToPts"
      onClick={() => {
        context.setModalOpen(false)
        saveCustomInput(props, value, context).then(() => {
          saveChangesToPts(props.dataKey, value, context, ptsForBulk.validOnly)
        })
        setEditMode(false)
      }}
    />
  ),
  buttonSecondary: (
    <ButtonSecondary
      modalButton
      content="doNotApplyChangesToPts"
      onClick={() => {
        context.setModalOpen(false)
        saveCustomInput(props, value, context)
        setEditMode(false)
      }}
    />
  )
})

export const getRelatedPtsForBulkInCustomInput = (pts, props, value) => {
  if (!props.showCopyToPtAlert) return { allPts: 0, validOnly: 0 }
  const allRelatedPts = pts.filter((pt) => pt.assessmentUuid === props.idValue) || []
  const validOnly = getValidPtsForBulkChanges(allRelatedPts, props.dataKey, value)
  const filteredPts = getFilteredPts(validOnly, props.dataKey, value)

  return {
    allPts: allRelatedPts,
    validOnly: filteredPts
  }
}

const getFilteredPts = (pts, key, value) => {
  switch (key) {
    case 'validFrom':
      return getPtsWhereValidFromIsValid(pts, value)
    case 'validUntil':
      return getPtsWhereValidUntilIsValid(pts, value)
    default:
      return pts
  }
}

const saveChangesToPts = (fieldName, value, context, relatedPts) => {
  const bulkParams = [[{ name: fieldName, value: value }], 'endPointEdit', ENTITIES.pts, relatedPts, context]
  applyBulkEdit(...bulkParams)
}
