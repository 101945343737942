import RowActions from '../components/table/RowActions'
import { ENTITIES, PT_STATUS } from '../utils/constants/constants'
import { getResultNr, isTraitsOrGoals } from '../utils/helper/Helper'
import { MainEntity } from './MainEntity'

export class PT extends MainEntity {
  constructor({ ...props }) {
    super()
    this.entity = 'PT'
    this.active = props.active
    this.assessmentUuid = props.assessmentUuid
    this.created = props.created
    this.createdBy = props.createdBy
    this.datePtFinished = props.datePtFinished
    this.datePtInvited = props.datePtInvited
    this.datePtStarted = props.datePtStarted
    this.durationMultiplier = props.durationMultiplier
    this.globalStatus = props.globalStatus
    this.lastProgressUpdate = props.lastProgressUpdate
    this.pNr = props.pNr
    this.ptCredits = props.ptCredits
    this.ptInvitations = props.ptInvitations
    this.ptInvitedDuration = props.ptInvitedDuration
    this.ptIsInvited = props.ptIsInvited
    this.ptModified = props.ptModified
    this.ptNumber = props.ptNumber
    this.ptProgress = props.ptProgress
    this.ptStartedDuration = props.ptStartedDuration
    this.ptStatus = props.ptStatus
    this.ptValidFrom = props.ptValidFrom
    this.ptValidUntil = props.ptValidUntil
    this.referenceToken = props.referenceToken
    this.relatedAssessment = null
    this.relatedConfig = null
    this.relatedParticipant = null
    this.relatedProcess = null
    this.relatedResult = null
    this.resultNr = getResultNr(this.ptNumber)
    this.visibility = props.visibility
    this.actions = <RowActions row={this} entity={ENTITIES.pts} />
  }
  addRelatedElements(completeDataSet) {
    const { results, participants, assessments, processes, usedConfigs } = completeDataSet

    this.relatedParticipant = participants.find((participant) => participant.pNr === this.pNr)
    this.relatedAssessment = assessments.find((assessment) => assessment.assessmentUuid === this.assessmentUuid)

    if (this.relatedAssessment) {
      this.relatedProcess = processes.find((process) =>
        process.assessmentUuids.includes(this.relatedAssessment.assessmentUuid)
      )
      this.relatedConfig = usedConfigs.find((config) => config.configUuid === this.relatedAssessment.configUuid)
    }
    this.relatedResult = results.find((result) => result.resultNr === this.resultNr)
  }

  hasCrosstestResult() {
    return this?.relatedResult?.crosstestResult ? true : false
  }
  hasResult() {
    return this.relatedResult ? true : false
  }
  isFinished() {
    return this.ptStatus === PT_STATUS.finished
  }
  isDeactivated() {
    return this.ptStatus === PT_STATUS.deactivated
  }
  isExpired() {
    return this.ptStatus === PT_STATUS.expired
  }
  isWaiting() {
    return this.ptStatus === PT_STATUS.waiting
  }
  isReady() {
    return this.ptStatus === PT_STATUS.ready
  }
  isStarted() {
    return this.ptStatus === PT_STATUS.started
  }
  isActive() {
    return this.active && this.ptProgress > 0 && this.ptStatus !== PT_STATUS.finished
  }
  resultIsVisible() {
    if (isTraitsOrGoals(this.relatedConfig.configType)) {
      return this.relatedAssessment.hasMatching()
    }
    return true
  }
  getRank() {
    const thisResult = this?.relatedResult?.normResult
    if (!thisResult) {
      return 0
    }

    const allResults = this.relatedAssessment.relatedPts
      .filter((pt) => pt.relatedResult)
      .map((pt) => pt.relatedResult.normResult)
    const rank = allResults.filter((result) => result > thisResult).length + 1
    const maxRank = allResults.length
    return {
      current: rank,
      max: maxRank
    }
  }
}
