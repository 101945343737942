export class Screen {
  constructor({ ...props }) {
    this.created = props.created
    this.createdBy = props.createdBy || 'PERSEO'
    this.modified = props.modified
    this.name = props.name
    this.screenNr = props.screenNr
    this.isDirty = props.isDirty || false
    this.disabled = props.disabled || false
    this.type = props.type
    this.visibility = props.visibility
    this.content = {
      de: {
        headline: getHeadline(props, 'de'),
        body: getBody(props, 'de')
      },
      en: {
        headline: getHeadline(props, 'en'),
        body: getBody(props, 'en')
      },
      ded: {
        headline: getHeadline(props, 'ded'),
        body: getBody(props, 'ded')
      }
    }
  }
}

const getHeadline = (props, locale) => {
  const html = props?.html?.[locale]
  if (html) {
    const rawHeadline = html.match(/<h1[^>]*>(.*?)<\/h1>/)?.[1]
    return rawHeadline?.replace(/{.*?}/g, '').trim()
  }
  return props?.content?.[locale].headline || ''
}

const getBody = (props, locale) => {
  const html = props?.html?.[locale]
  if (html) {
    const contentWithoutInlineStyles = html.replace(/style="[^"]*"/g, '')
    const contentWithoutNotices = contentWithoutInlineStyles.replace(
      /<span class="notice tablet-notice">.*?<\/span>/gs,
      ''
    )
    const contentWithoutEmptyTags = contentWithoutNotices.replace(/<(\w+)[^>]*>\s*<\/\1>/g, '')
    const sanitizedString = contentWithoutEmptyTags.substring(contentWithoutEmptyTags.indexOf('</h1>') + 5).trim()

    return sanitizedString
  }
  return props?.content?.[locale].body || ''
}
