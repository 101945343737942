import RowActions from '../components/table/RowActions'
import {
  CAMERA_SUPERVISION_WITHOUT_ID_CHECK,
  CAMERA_SUPERVISION_WITH_ID_CHECK,
  ENTITIES
} from '../utils/constants/constants'
import { isPotentials, isPotentialsCrossTest, isTraitsOrGoals } from '../utils/helper/Helper'
import { MainEntity } from './MainEntity'
import { Screen } from './Screen'

export class Assessment extends MainEntity {
  constructor({ ...props }) {
    super()
    this.entity = 'Assessment'
    this.assessmentDescription = props.assessmentDescription
    this.assessmentName = props.assessmentName
    this.assessmentUuid = props.assessmentUuid
    this.configUuid = props.configUuid
    this.created = props.created
    this.createdBy = props.createdBy
    this.inHub = props.inHub
    this.invitedDuration = props.invitedDuration
    this.isSupervised = props.isSupervised
    this.jobType = props.jobType
    this.languageIds = props.languageIds
    this.normNr = props.normNr
    this.referenceAssessmentUuid = props.referenceAssessmentUuid
    this.relatedConfig = {}
    this.relatedProcess = {}
    this.relatedPts = []
    this.reportAvailable = props.reportAvailable
    this.reportLanguage = props.reportLanguage
    this.reportModules = props.reportModules
    this.startedDuration = props.startedDuration
    this.targetGroup = props.targetGroup
    this.testCredits = props.testCredits
    this.useCameraSupervision = props.useCameraSupervision
    this.useChildItems = props.useChildItems
    this.useTabTracking = props.useTabTracking
    this.validFrom = props.validFrom
    this.validUntil = props.validUntil
    this.visibility = props.visibility
    this.weight = props.weight
    this.dimLogicNr = props.dimLogicNr || null
    this.screens = {
      screenNrStart: props.screens.screenNrStart || null,
      screenNrEnd: props.screens.screenNrEnd || null,
      defaultStartScreen: new Screen(props.screens.defaultStartScreen),
      defaultEndScreen: new Screen(props.screens.defaultEndScreen)
    }

    this.actions = <RowActions row={this} entity={ENTITIES.assessments} />
  }

  addRelatedElements(completeDataSet) {
    const { processes, usedConfigs: configs, pts } = completeDataSet

    this.relatedPts = pts.filter((pt) => pt.assessmentUuid === this.assessmentUuid)
    this.relatedConfig = configs.find((config) => config.configUuid === this.configUuid) || {}
    this.relatedProcess = processes.find((process) => process.assessmentUuids.includes(this.assessmentUuid)) || {}
  }

  hasMatching() {
    return this?.reportModules.matching === 1
  }

  penAndPaperRequired() {
    return isPotentials(this.relatedConfig.configType) || isPotentialsCrossTest(this.relatedConfig.configType)
  }

  cameraRequired() {
    return [CAMERA_SUPERVISION_WITHOUT_ID_CHECK, CAMERA_SUPERVISION_WITH_ID_CHECK].includes(this.useCameraSupervision)
  }

  photoIdRequired() {
    return this.useCameraSupervision === CAMERA_SUPERVISION_WITH_ID_CHECK
  }

  getRequiredMaterials() {
    let materials = []
    if (this.penAndPaperRequired()) {
      materials.push('penAndPaper')
    }
    if (this.cameraRequired()) {
      materials.push('camera')
    }
    if (this.photoIdRequired()) {
      materials.push('photoId')
    }
    return materials
  }

  producesNumericResult() {
    if (isTraitsOrGoals(this.relatedConfig.configType)) {
      return this.hasMatching()
    }
    return isPotentials(this.relatedConfig.configType)
  }
}
