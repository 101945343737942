import { useContext } from 'react'
import ReactTooltip from 'react-tooltip'
import { BREAKPOINT_MOBILE } from '../../utils/constants/constants'
import { PAGES } from '../../utils/constants/pages'
import { AppContext } from '../../utils/context/AppContext'
import useCurrentRect from '../../utils/hooks/useCurrentRect'
import useTranslate from '../../utils/hooks/useTranslate'
import usePageUrl from '../../utils/hooks/usePageUrl'

import {
  NavLink,
  NavLinkLabel,
  NavMenuInnerContainer,
  NavMenuItem,
  NavMenuSubHeadline,
  NavMenuUl,
  SettingsNav
} from './NavbarElements'

const SettingsNavBar = () => {
  const { loggedInAsAdmin } = useContext(AppContext)
  const t = useTranslate()

  const navDataAdmin = [
    { label: 'accountSettings', page: PAGES.accountSettings, icon: 'icon-customer' },
    { label: 'userAdministration', page: PAGES.users, icon: 'icon-users' },
    { label: 'dataMaintenance', page: PAGES.dataSecurity, icon: 'icon-broom' },
    { label: 'customization', page: PAGES.customization, icon: 'icon-fingerprint' }
  ]

  const navDataUser = [
    { label: 'userSettings', page: PAGES.userSettings, icon: 'icon-account' },
    { label: 'notifications', page: PAGES.notifications, icon: 'icon-bell' },
    { label: 'displayAndLanguage', page: PAGES.displayAndLanguageSettings, icon: 'icon-display' }
  ]

  return (
    <SettingsNav type="settings">
      <NavMenuUl className="settings-menu">
        {loggedInAsAdmin && (
          <>
            <NavMenuSubHeadline>{t('company')}</NavMenuSubHeadline>
            {navDataAdmin.map((entry) => (
              <Item key={entry.label} label={entry.label} page={entry.page} icon={entry.icon} />
            ))}
          </>
        )}

        <NavMenuSubHeadline style={{ marginTop: loggedInAsAdmin && 'var(--space-5)' }}>{t('user')}</NavMenuSubHeadline>

        {navDataUser.map((entry) => (
          <Item key={entry.label} label={entry.label} page={entry.page} icon={entry.icon} />
        ))}
      </NavMenuUl>
    </SettingsNav>
  )
}

export default SettingsNavBar

const Item = ({ label, page, icon }) => {
  const width = useCurrentRect().width
  const isMobile = width < BREAKPOINT_MOBILE
  const tooltipPlacement = isMobile ? 'bottom' : 'right'
  const t = useTranslate()
  const getUrlFromPage = usePageUrl()

  return (
    <>
      <NavMenuItem data-tip={t(label)} data-for="nav-tip">
        <NavLink type="settings" to={getUrlFromPage(page)}>
          <NavMenuInnerContainer>
            <span className={`svg-icon ${icon}`} />
            <NavLinkLabel>{t(label)}</NavLinkLabel>
          </NavMenuInnerContainer>
        </NavLink>
      </NavMenuItem>
      <ReactTooltip
        id="nav-tip"
        aria-haspopup="true"
        effect="solid"
        place={tooltipPlacement}
        backgroundColor="var(--c-tooltip-bg)"
        textColor="var(--tooltip-text-color)"
        className="navbar-tooltip"
      />
    </>
  )
}
