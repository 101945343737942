import { useContext } from 'react'
import useTranslate from '../../../utils/hooks/useTranslate'
import { AppContext } from '../../../utils/context/AppContext'
import { getLoggedInContact } from '../../../utils/helper/Helper'
import { Screen } from '../../../entities/Screen'
import { getDefaultKey, getEmptyElement } from './sharedUtils'

const useEditorReducer = (keyName) => {
  const t = useTranslate()
  const context = useContext(AppContext)
  const contact = getLoggedInContact(context.completeDataSet)

  function editorReducer(data, action) {
    const currentElement = data.elements.find((n) => n[keyName] === data.selectedkey)

    switch (action.type) {
      case 'added_row': {
        const insertAt = action.index + 1
        const updatedTable = [
          ...currentElement.normTable.slice(0, insertAt),
          {
            lower_limit: currentElement.normTable[action.index].upper_limit,
            upper_limit: '',
            grade: '',
            verbalisation: '',
            upperTooLow: true
          },
          ...currentElement.normTable.slice(insertAt)
        ]

        const updatedNorms = data.elements.map((norm) => {
          if (norm[keyName] === data.selectedkey) {
            return { ...norm, normTable: updatedTable }
          } else {
            return norm
          }
        })

        updatedNorms.find((n) => n[keyName] === data.selectedkey).isDirty = true
        return { ...data, elements: updatedNorms }
      }

      case 'removed_row': {
        const updatedTable = currentElement.normTable
          .map((row, i, orig) => {
            if (i === action.index + 1) {
              return { ...row, lower_limit: orig[action.index - 1].upper_limit }
            } else {
              return { ...row }
            }
          })
          .filter((_, i) => i !== action.index)

        const updatedNorms = data.elements.map((norm) => {
          if (norm[keyName] === data.selectedkey) {
            return { ...norm, normTable: updatedTable }
          } else {
            return norm
          }
        })

        updatedNorms.find((n) => n[keyName] === data.selectedkey).isDirty = true
        return { ...data, elements: updatedNorms }
      }

      case 'renamed_element': {
        currentElement.name = action.value
        currentElement.isDirty = true
        return { ...data, renameFieldVisible: false, elements: [...data.elements] }
      }

      case 'updated_elements': {
        return {
          ...data,
          selectedkey: action.selectedkey,
          elements: action.elements
        }
      }

      case 'updated_table': {
        const { field, rowIndex } = action
        let { value } = action
        const MAX_INPUT = 90

        if (value < 0) {
          value = 0
        }

        if (field === 'upper_limit' && value > MAX_INPUT) {
          value = MAX_INPUT
        }

        if (field === 'upper_limit' && isNaN(value)) {
          value = ''
        }

        const updatedTable = currentElement.normTable.map((row, i) => {
          if (field === 'upper_limit' && i === rowIndex + 1) {
            return { ...row, lower_limit: value }
          } else if (i === rowIndex) {
            return { ...row, [field]: value }
          } else {
            return { ...row }
          }
        })

        const validatedTable = updatedTable.map((row, i) => {
          const upperTooLow = i > 0 && updatedTable[i].upper_limit <= updatedTable[i].lower_limit
          return { ...row, upperTooLow: upperTooLow }
        })

        const updatedNorms = data.elements.map((norm) => {
          if (norm[keyName] === data.selectedkey) {
            return { ...norm, normTable: validatedTable }
          } else {
            return norm
          }
        })

        updatedNorms.find((n) => n[keyName] === data.selectedkey).isDirty = true
        return { ...data, elements: updatedNorms }
      }

      case 'empty_element_added': {
        const emptyElement = getEmptyElement(keyName, t, contact, action.options)
        return {
          ...data,
          screenTypeSelectionVisible: false,
          renameFieldVisible: true,
          selectedkey: emptyElement[keyName],
          elements: [...data.elements, emptyElement]
        }
      }

      case 'key_set': {
        return { ...data, selectedkey: action.value }
      }

      case 'toggle_rename_field': {
        return { ...data, renameFieldVisible: !data.renameFieldVisible }
      }

      case 'save_as_dialog_visibility_set': {
        return { ...data, saveAsDialogVisible: action.value }
      }

      case 'removed_element': {
        const { keyToRemove } = action
        return {
          ...data,
          selectedkey: getDefaultKey(keyName),
          elements: data.elements.filter((n) => n[keyName] !== keyToRemove)
        }
      }

      case 'updated_screen_content': {
        const { content, field, language } = action

        const updatedContent = { ...currentElement.content }
        updatedContent[language][field] = content

        return {
          ...data,
          elements: data.elements.map((element) => {
            if (element.screenNr === currentElement.screenNr) {
              return new Screen({ ...element, content: updatedContent, isDirty: true })
            } else {
              return element
            }
          })
        }
      }
      case 'updated_screen': {
        const { value, property } = action
        return {
          ...data,
          elements: data.elements.map((element) => {
            if (element.screenNr === currentElement.screenNr) {
              return new Screen({ ...element, [property]: value, isDirty: true })
            } else {
              return element
            }
          })
        }
      }
      case 'updated_language': {
        const { language } = action
        return {
          ...data,
          selectedLanguage: language
        }
      }
      case 'screen_type_selection_visibility_set': {
        return { ...data, screenTypeSelectionVisible: action.value }
      }

      default: {
        throw Error('Unknown action: ' + action.type)
      }
    }
  }

  return editorReducer
}

export default useEditorReducer
